import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect:'/index'
  },
  {
    path: '/personCenter',
    name: 'personCenter',
    component: () => import(/* webpackChunkName: "personCenter" */ '../views/personCenter/index')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login/index')
  },
  {
    path: '/loginComponent',
    name: 'loginComponent',
    component: () => import(/* webpackChunkName: "loginComponent" */ '../views/LoginComponent/index')
  },
  {
    path: '/index',
    name: 'index',
    component: () => import(/* webpackChunkName: "index" */ '../views/Index/index')
  },
  {
    path: '/liveStreaming/:id/:state',
    name: 'liveStreaming',
    component: () => import(/* webpackChunkName: "liveStreaming" */ '../views/liveStreaming/index')
  },
  {
    path: '/MyCourse',
    name: 'MyCourse',
    component: () => import(/* webpackChunkName: "MyCourse" */ '../views/MyCourse/index')
  },
  {
    path: '/moreCourse/:activeName',
    name: 'MoreCourse',
    component: () => import(/* webpackChunkName: "MoreCourse" */ '../views/Index/moreCourse/index')
  },
  {
    path: '/moreFreeVideo/:activeName/:subject_id',
    name: 'MoreFreeVideo',
    component: () => import(/* webpackChunkName: "MoreFreeVideo" */ '../views/Index/moreFreeVideo/index')
  },
  {
    path: '/freePlayer/:id/:subject_id',
    name: 'FreePlayer',
    component: () => import(/* webpackChunkName: "FreePlayer" */ '../views/Index/moreFreeVideo/freePlayer/index')
  },
  {
    path: '/moreBroadCast/:state',
    name: 'MoreBroadCast',
    component: () => import(/* webpackChunkName: "MoreBroadCast" */ '../views/Index/moreBroadCast/index')
  },
  {
    path: '/moreInfoMation/:activeName',
    name: 'MoreInfoMation',
    component: () => import(/* webpackChunkName: "MoreInfoMation" */ '../views/Index/moreInfoMation/index')
  },
  {
    path: '/infoMationMessage/:n_id/:activeName',
    name: 'InfoMationMessage',
    component: () => import(/* webpackChunkName: "InfoMationMessage" */ '../views/Index/moreInfoMation/infoMationMessage/index')
  },
  {
    path: '/lookCourse/:c_id/:activeName',
    name: 'LookCourse',
    component: () => import(/* webpackChunkName: "LookCourse" */ '../views/Index/moreInfoMation/lookCourse/index')
  },
  {
    path: '/freeVideo',
    name: 'FreeVideo',
    component: () => import(/* webpackChunkName: "FreeVideo" */ '../views/Index/moreCourse/tabs/freeVideo')
  },
  {
    path: '/MyCourse/Account',
    name: 'MyCourseAccount',
    component: () => import(/* webpackChunkName: "MyCourseAccount" */ '../views/MyCourse/Account/index')
  },
  {
    path: '/VideoPlayer/:subject_id/:subject_name/:id/:chapterID',
    name: 'VideoPlayer',
    component: () => import(/* webpackChunkName: "VideoPlayer" */ '../views/MyCourse/VideoPlayer/index')
  },
  {
    path: '/MyQuestionBank',
    name: 'MyQuestionBank',
    // redirect:'/MyQuestionBank/AccountList',
    component: () => import(/* webpackChunkName: "MyQuestionBank" */ '../views/MyQuestionBank/index'),
    meta:{title:'我的题库'}
  },
  {
    path: '/tableOfRates',
    name: 'TableOfRates',
    component: () => import(/* webpackChunkName: "TableOfRates" */ '../views/MyQuestionBank/tableOfRates/index'),
    meta:{title:'税率表'}
  },
  {
    path: '/AccountList',
    name: 'AccountList',
    component: () => import(/* webpackChunkName: "AccountList" */ '../views/MyQuestionBank/AccountList/index'),
    meta:{title:'科目详情'}
  },
  {
    path: '/DailyPractice/:subject_name/:id/:state',
    name: 'DailyPractice',
    component: () => import(/* webpackChunkName: "DailyPractice" */ '../views/MyQuestionBank/DailyPractice/index'),
    meta:{title:'每日一练'}
  },
  {
    path: '/ChapterExercise/:subject_name/:id',
    name: 'ChapterExercise',
    component: () => import(/* webpackChunkName: "ChapterExercise" */ '../views/MyQuestionBank/ChapterExercise/index'),
    meta:{title:'章节练习'}
  },
  {
    path: '/ChapterHistory/:id/:state',
    name: 'ChapterHistory',
    component: () => import(/* webpackChunkName: "ChapterHistory" */ '../views/MyQuestionBank/ChapterHistory/index'),
    meta:{title:'练习记录详情'}
  },
  {
    path: '/RecentStudy/:activeName',
    name: 'RecentStudy',
    component: () => import(/* webpackChunkName: "RecentStudy" */ '../views/RecentStudy/index'),
    meta:{title:'最近学习'}
  },
  {
    path: '/ExamExercise/:subject_name/:id/:method/:fraction',
    name: 'ExamExercise',
    component: () => import(/* webpackChunkName: "ExamExercise" */ '../views/MyQuestionBank/ExamExercise/index'),
    meta:{title:'整卷练习'}
  },
  {
    path: '/Collect_or_Question/:subject_name/:id/:method',
    name: 'Collect_or_Question',
    component: () => import(/* webpackChunkName: "Collect_or_Question" */ '../views/MyQuestionBank/Collect_or_Question/index'),
    meta:{title:'单个题目详情'}
  },
  {
    path: '/Collect_or_QuestionAll/:subject_name/:type/:topicIDs/:isCourse/:method',
    name: 'Collect_or_QuestionAll',
    component: () => import(/* webpackChunkName: "Collect_or_QuestionAll" */ '../views/MyQuestionBank/Collect_or_QuestionAll/index'),
    meta:{title:'多个题目组合习题'}
  },
  {
    path: '/TopicDrill/:subject_name/:id/:method/:fraction/:scopeTime',
    name: 'TopicDrill',
    component: () => import(/* webpackChunkName: "TopicDrill" */ '../views/MyQuestionBank/TopicDrill/index'),
    meta:{title:'真题演练'}
  },
  {
    path: '/MockExam/:subject_name/:id/:exam_code/:card_id/:method/:fraction/:scopeTime/:exam_name',
    name: 'MockExam',
    component: () => import(/* webpackChunkName: "MockExam" */ '../views/MyQuestionBank/MockExam/index'),
    meta:{title:'模拟考试'}
  },
  {
    path: '/TrainingPlatform',
    name: 'TrainingPlatform',
    component: () => import(/* webpackChunkName: "TrainingPlatform" */ '../views/TrainingPlatform/index')
  },
  {
    path: '/CompetitionRegistration',
    name: 'CompetitionRegistration',
    component: () => import(/* webpackChunkName: "CompetitionRegistration" */ '../views/CompetitionRegistration/index')
  },
  {
    path: '/search/:activeName',
    name: 'Search',
    component: () => import(/* webpackChunkName: "Search" */ '../views/SearchContent/index')
  },
  {
    path: '/pdfContainer/:pdf',
    name: 'pdfContainer',
    component: () => import(/* webpackChunkName: "pdfContainer" */ '../views/pdfContainer/index')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
