import Cookie from 'js-cookie'
const deleteLocalFun = () =>{
    const end = new Date(new Date(new Date().toLocaleDateString()).getTime()+11*60*60*1000-1).getTime() //当天23：59：59秒 转换成的毫秒数

    const start = new Date().getTime() //当前时间的毫秒数

    if(localStorage.getItem('endTime')){	//首先判断本地有没有存入当天23：59：59秒的毫秒数
        if(start > localStorage.getItem('endTime')){ //当前时间毫秒数大于当天23：59：59秒 条件成立
            console.log('定点删除---')
            window.localStorage.clear();
            Cookie.remove('token');
        }
    }else{
        localStorage.setItem('endTime',end) //存入当天晚上23：59：59秒的毫秒数
    }
}

export default {
    deleteLocalFun
}