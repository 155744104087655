import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// import './util/rem'
import Cookie from 'js-cookie'
import moment from 'moment'
import Vue3DraggableResizable from 'vue3-draggable-resizable'
import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css'
import countdown from 'kenyang-countdown-vue';
import '@/components/Player/js/aliplayercomponents-1.0.5.min' //引入阿里播放器额外组件
import './assets/feng_css.css'
import TIM from "tim-js-sdk";
import deleteLocal from "@/utils/deleteLocal";

let token = Cookie.get("token")

// console.log('mian-token', token)
if (token) {
    store.commit('setToken', token)
}

import * as ElementPlusIconsVue from '@element-plus/icons-vue'


const app = createApp(App);

app.config.globalProperties.$filters = {
    dateTimeFormat(value, formatString) {
        formatString = formatString || 'YYYY-MM-DD HH:mm:ss';
        return moment(value).format(formatString);
    },
    dateTimeFormat2(value, formatString) {
        formatString = formatString || 'YYYY年MM月DD日';
        return moment(value).format(formatString);
    },
    dateFormat(originVal) {
        // 先把传参毫秒转化为new Date()
        const dt = new Date(originVal)
        const y = dt.getFullYear()
        // 月份是从0开始,需要+1。  +''是把数字转化为字符串,padStart(2,'0')是把字符串设置为2位数,不足2位则在开头加'0'
        const m = (dt.getMonth() + 1 + '').padStart(2, '0')
        const d = (dt.getDate() + '').padStart(2, '0')
        return `${y}-${m}-${d}`
    },
    timeDown(totalTime) {
        let n = 3600;
        let arr = [];
        for (let i = 0; i < 3; i++) {
            [arr[i], totalTime] = [Number.parseInt(totalTime / n), totalTime % n]
            n = n / 60
        }
        arr = arr.map((item) => item >= 10 ? item : '0' + item)
        let [hour, minute, second] = arr;
        return `${hour}:${minute}:${second}`
    },
    timeFormat(originVal) {
        let mss = originVal * 1000
        let hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) < 10 ? '0' + parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) : parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60)) < 10 ? '0' + parseInt((mss % (1000 * 60 * 60)) / (1000 * 60)) : parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = (mss % (1000 * 60)) / 1000 < 10 ? '0' + (mss % (1000 * 60)) / 1000 : (mss % (1000 * 60)) / 1000
        return hours + ":" + minutes + ":" + seconds;
    }
}

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
//我实在main.js中引入的，下面的debug,自行去文档查看，把两个文件图放上面了
import GenerateTestUserSig, {
    SDKAPPID
} from '../debug/GenerateTestUserSig'

// 引入tim
// import TIM from "tim-js-sdk"
//
// import TIMUploadPlugin from 'tim-upload-plugin'
//
// let options = {
//     SDKAppID: 1400668788 //将0替换为及时通信IM应用的SDKAppID
// }
//
// let tim = TIM.create(options)
//
// tim.setLogLevel(0)//设置SDK日志输出级别
//
// tim.registerPlugin({'tim-upload-plugin': TIMUploadPlugin})//注册COS SDK插件
// console.log('tim',tim)
// app.config.globalProperties.$tim = tim


app.use(store).use(router).use(ElementPlus).use(Vue3DraggableResizable).use(countdown).mount('#app')
