<template>
  <router-view/>
</template>


<script>

</script>
<style lang="scss">
*{
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
</style>
